@use "sass:math";

@import "styles/variables";
@import "styles/mixings";

.box {
  .top {
    color: white;
    background: #232323;
    position: relative;
    height: 222px;
    overflow: hidden;

    .mountains {
      svg {
        position: absolute;
        bottom: 0;

        &.m1 {
          height: 207px;
          right: 0;
        }
        &.m2 {
          height: 89px;
          right: 170px;
          bottom: -20px;
        }
        &.m3 {
          height: 89px;
          left: -20px;
        }
      }
    }

    .content {
      position: relative;
      z-index: 1;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .left {
        .bold {
          margin-top: 68px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          svg {
            fill: white;
            height: 30px;
            margin-right: 36px;
          }

          a {
            color: white;
            font-size: math.div($size-base * 18, 16);
            font-weight: 700;
          }
          span {
            padding: 0 $size-base;
            font-weight: normal;
            opacity: 0.5;
          }

          .navLinksContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .block {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: 6px;
            }

            .support {
              a {
                font-weight: 400;
                font-size: math.div($size-base * 13, 16);
              }
            }
          }
        }

        .small {
          margin-top: 45px;
          font-size: math.div($size-base * 12, 16);
          color: #6f6f6f;

          a {
            color: #6f6f6f;
          }

          span {
            padding: 0 $size-base;
          }

          .unitToggle {
            display: inline-flex;
            justify-content: center;

            @include media-breakpoint-down(lg) {
              width: 100%;
            }

            div {
              display: inline-block;
              padding-right: $size-base * 0.3;
            }

            a {
              cursor: pointer;
              padding-right: $size-base * 0.1;
              padding-left: $size-base * 0.1;
              opacity: 0.5;

              &.active {
                color: white;
                opacity: 1;
              }
            }
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .social {
          display: flex;
          padding-top: 56px;
          a {
            margin-left: 12px;
            width: 43px;
            height: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #333;
            border-radius: 4px;
            transition: all 0.2s ease;
            svg {
              height: 16px;
              fill: white;
              transition: all 0.2s ease;
            }
            &:hover {
              background: $white;
              border-color: $white;
              svg {
                fill: $primary-alt;
              }
            }
          }
        }

        .apps {
          padding-top: 13px;
          a {
            margin-left: 7px;
          }
          svg {
            height: 40px;
            fill: white;
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      height: auto;
      padding-bottom: 44px;

      .content > div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .mountains {
        svg.m1 {
          right: -170px;
        }
        svg.m2 {
          right: 0;
        }
      }

      .content {
        .left .bold,
        .small {
          svg {
            margin-right: 0;
            height: 44px;
            margin-bottom: 42px;
          }

          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            display: none;
          }
        }

        .left {
          .bold {
            .navLinksContainer {
              .block {
                flex-direction: column;
                align-items: center;
              }
            }

            a {
              font-size: 16 * $px-base;
              margin-bottom: 7px;
            }
          }

          .small {
            margin-top: 0;
            a {
              text-align: center;
              display: block;
              margin-bottom: 7px;
            }
          }
        }

        .right {
          .social {
            padding-top: 24px;
          }
        }
      }
    }
  }

  .bottom {
    background: #0e0e0e;
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: math.div($size-base * 12, 16);
    color: rgba(255, 255, 255, 0.6);

    a {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: underline;
    }

    @include media-breakpoint-down(lg) {
      height: auto;
      min-height: 50px;
      padding: 10px 0 env(safe-area-inset-bottom) 0;
      text-align: center;
    }
  }
}
